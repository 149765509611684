<template>
    <div class="clinic-dashboard-footer">
      <vs-collapse>
       <vs-collapse-item>
         <div slot="header">
            General Disclaimer
         </div>
         The information contained in these dashboards is for general information only and should not be relied upon in isolation to make business decisions. While efforts have been made to ensure the accuracy of the information displayed, Fresh Clinics does not guarantee the accuracy of the data displayed, or the underlying data which is based on the information entered by a Clinic into the Fresh Clinics app and is subject to calculation error.
         <br><br>
        The information provided is for educational purposes only and is not professional medical advice. Users should always consult a qualified medical professional before making treatment recommendations or providing any form of medical advice.
        <br><br>
        Nothing contained in these dashboards should be taken as constituting professional advice and you should consider seeking independent strategic, financial, legal, taxation or other advice relating to your unique circumstances prior to making any treatment recommendations or changes to your business.
        <br><br>
        Fresh Clinics is not liable for any loss caused, whether due to negligence or otherwise arising from the use of, or reliance on, the information provided directly or indirectly, by use of these dashboards
       </vs-collapse-item>
       <vs-collapse-item>
         <div slot="header">
            High Volume Clinics Definition
        </div>
         All comparisons to "High Volume Clinics" are based on the average performance of a subset of large clinics within the Fresh Clinics network based on volume of appointments. When comparing your performance to that of the average High Volume Clinic it is important to consider that there are a range of factors that may not make the comparison 'like for like', including; opening hours, number of employees, experience, patient needs, etc. Therefore, these numbers should not be relied upon as a direct comparison and should be considered in this context.
       </vs-collapse-item>
       <vs-collapse-item>
         <div slot="header">
            Average Treatment Volume for High Volume Clinics
        </div>
         Average Treatment Volumes for High Volume Clinics has been calculated based on the average treatment volumes for the 100 nurses with the highest treatment volume in the Fresh Clinics network.
         <br><br>
        The range provided has been calculated based on:
        <ul>
            <li>- All treatments complete by the 100 nurses </li>
            <li>- Removal of treatments that Fresh believes to be top-up treatments, rather than full treatments</li>
        </ul>
        <br>
        The bottom end of the range is mean of the remaining treatments, with the upper end of the range being the mean + 2 standard deviations
       </vs-collapse-item>
      </vs-collapse>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    }
}
</script>
<style>
.clinic-dashboard-footer .vs-collapse-item {
    margin-bottom: 12px;
}
.clinic-dashboard-footer .vs-collapse-item--header{
    border-radius: 8px 8px 0 0;
    border: 1px solid #E0E0E0;
    font-size: 16px;
    font-family: 'InterSemiBold';
    color: #0E4333;
}
.clinic-dashboard-footer .vs-collapse-item--header div {
  padding-right: 25px;
}
.clinic-dashboard-footer .vs-collapse-item--content {
    border-radius: 0 0 8px 8px;
    border: 1px solid #E0E0E0;
    border-top: none;
    border-bottom: none;
    -webkit-transition: max-height .2s ease;
    transition: max-height .2s ease;
}
.clinic-dashboard-footer .open-item .vs-collapse-item--content {
    border-bottom: 1px solid #E0E0E0;
}

.clinic-dashboard-footer  .icon-header {
    color: #0E4333;
}

.clinic-dashboard-footer  .icon-header i {
    font-size: 28px;

}

</style>
